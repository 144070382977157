
// @mui
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Container, Typography } from '@mui/material';

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useLocales from '../../../hooks/useLocales';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import LanguagePopover from '../../../components/LanguagePopover';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MOBILE_HEIGHT,
  },
}));

export default function DashboardHeader() {
  const isOffset = useOffSetTop(HEADER.MOBILE_HEIGHT);
  const { translate } = useLocales();
  const theme = createTheme();

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        color="warning"
        elevation={8}
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgOpacity(),
            // height: { md: HEADER.MOBILE_HEIGHT - 16 },
          }),
        }}
      >
        <ToolbarStyle
          disableGutters
          sx={{
            ...(isOffset && {
              ...cssStyles(theme).bgOpacity(),
              height: { md: HEADER.MOBILE_HEIGHT - 16 },
            }),
          }}
        >
          <Container maxWidth="xl"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Logo />
              <Typography variant="h6" color="white" noWrap ml={2} sx={{ display: 'inline-block', }}>
                {translate('High National Elections Commission')}
              </Typography>
            </Box>


            <Typography variant="h6" color="white">
              {translate('Find Registered NGO')}
            </Typography>

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <LanguagePopover color='white'/>
            </Stack>
          </Container>
        </ToolbarStyle>
      </AppBar>
    </ThemeProvider>
  );
}
