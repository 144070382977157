import { useAuth } from "react-oidc-context"

// import { useIdleTimer } from 'react-idle-timer';
// routes
import Router from './routes';



// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';



// ----------------------------------------------------------------------

export default function App() {

  const auth = useAuth();

  const onIdle = () => {
    // console.log('log out after 5 min idle');
    if (auth.isAuthenticated)
    {
      auth.signoutRedirect();
    }
  }

  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   timeout: 1000 * 60 * 5,
  // })

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
