import { menu } from './english/menu'
import { general } from './english/general'
import { catalogues } from './english/catalogues'
import { main } from './english/main'
import { ngo } from './english/ngo'
import { management } from './english/management'
import {  dashboard   } from  './english/dashboard'
import {intro} from './english/intro'
import {observer} from './english/observer'
import {organization} from './english/organization'
import {reports} from './english/reports'

const ar = {
  ...menu,
  ...general,
  ...catalogues,
  ...main,
  ...ngo,
  ...management,
  ...dashboard,
  ...intro,
  ...observer,
  ...organization,
  ...reports
};

export default ar;