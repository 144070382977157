export const intro = {
  'Application for': 'نظـــام',
  'management of': ' إدارة',
  Observers: ' المــراقبــين',
  'Only for': 'فقط',
  Authorized: 'للـموظفين',
  staff: '.المــصرح لهــم',
  'To dashboard': 'الذهاب للوحــة التحـكـم',
  'NGO Registration': ' تســجيل منـظمات المجـتمع المدني',
};
