export const dashboard = {
  Agent:'وكيل المرشح',
  'Local Observer': 'مراقب محلى',
  'Local Media': 'ممثل الاعلام المحلى',
  'International Observer': 'مراقب دولى',
  'International Media': 'ممثل الاعلام الدولى',
  Guest:'ضيف',
  Candidate:'المرشح',
  'Local Organization':'المنظمات المحلية',
  'Local Media Authority':'الاعلام المحلى',
  'International Organization':'المنظمات الدولية',
  'International Media Authority':'الاعلام الدولى',
  'Diplomatic Mission':'هيئة دبلوماسية',
  'Total':'المجموع',
  'Registered NGO':'منظمات المجتمع المدنى المؤكدة',
  'Non Approved NGO':'منظمات المجتمع المدنى الغير مؤكدة'
};
