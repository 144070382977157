import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import localtableReducer from './slices/localtable';
import userProfileReducer from './slices/userProfile';
import ngoReducer from './slices/ngo';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};


const rootReducer = combineReducers({
  localtable: localtableReducer,
  userProfile: userProfileReducer,
  ngo: ngoReducer
});

export { rootPersistConfig, rootReducer };
