export const general = {
    general: `general`,
    user: `user`,
    name: `name`,
    translation: `translation`,
    list: `list`,
    edit: `edit`,
    create: `create`,
    profile: `profile`,
    account: `account`,
    details: `details`,
    description: `description`,
    'New observer':'New observer',
    'personal ID' : 'National ID',
    'genderName':'Gender Name',
    phone:`phone`,
    email: `email`,
    NGOs:'NGOs',
    'Search by name':'Search by name',
    'Approve':'Approve',
    'Delete':'Delete',
    'Details':'Details',
    'reg. number is required':'reg. number is required'
};