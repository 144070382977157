export const ngo = {
  'NGO Management': 'NGO Management',
  'Reasone is required': 'Reasone is required',
  'Is the organization general or specialized, targeting a specific group or specific activity? Please mention the specialization?':
    'Is the organization general or specialized, targeting a specific group or specific activity? Please mention the specialization?',
  'Database info is required': 'Database info is required',
  ageGroupSpecialization: 'Age Group Specialization',
  qualitySpecialization: 'Quality Specialization',
  ethnicSpecialization: 'Ethnic Specialization',
  geographicSpecialization: 'Geographic Specialization',
  socialSpecialization: 'Social Specialization',
  charitySpecialization: 'Charity Specialization',
  humanRightSpecialization: 'Human Right Specialization',
  electionSpecialization: 'Election Specialization',
  otherSpecialization: 'Other Specialization',
  otherSpecializationDescription: 'Other Specialization Description',
  reasoneStatement: 'Reasone Statement',
  visionStatement: 'Vision Statement',
  Yes: 'Yes',
  No: 'No',
  'Do you have a database in your field of specialization?': 'Do you have a database in your field of specialization?',
  databaseObjective: 'Database Objective',
  'What kind of support do you need': 'What kind of support do you need?',
  Technical: 'Technical',
  Logistical: 'Logistical',
  Financial: 'Financial',
  'Organization name is required': 'Organization name is required',
  'Registration Number is required': 'Registration Number is required',
  'Start Date is required': 'Start Date is required',
  'Office is required': 'Office is required',
  'Manager name is required': 'Manager name is required',
  'Manager phone is required': 'Manager phone is required',
  'Director name is required': 'Director name is required',
  'Director phone is required': 'Director phone is required',
  'Email is not valid': 'Email is not valid',
  'Staff total count is required': 'Staff total count is required',
  'Youth staff count is required': 'Youth staff count is required',
  'Female staff count is required': 'Female staff count is required',
  Organization: 'Organization',
  startDate: 'Start Date',
  closeDate: 'Close Date',
  'Organization Name': 'Organization Name',
  registrationNumber: 'Registration Number',
  RegistrationNumberFile : 'Registration Number File',
  managerName: 'Manager Name',
  managerPhone: 'Manager Phone',
  directorName: 'Director Name',
  directorEmail: 'Director Email',
  directorPhone: 'Director Phone',
  femaleStaffCount: 'Female Staff Count',
  yourthStaffCount: 'Yourth Staff Count',
  staffCount: 'Staff Count',
  'Geographical distribution of the organization': 'Geographical distribution of the organization',
  includeWest: 'West',
  includeEast: 'East',
  includeSouth: 'South',
  includeCountry: 'Country',
  organizationFeatures: 'Organization Features',
  'What are the advantages of your organization compared to other civil society organizations in Libya':
    'What are the advantages of your organization compared to other civil society organizations in Libya',
  officeID: 'Office',
  Activities: 'Activities',
  'Relationship with HNEC': 'Relationship with HNEC in observation field',
  'Previous Experience': 'Relationship with HNEC in Awareness field',
  Financing: 'Financing',
  'Building Needs': 'Building Needs',
  Next: 'Next',
  'Hnec Support Team': 'Hnec Support Team',
  'NGO Registration System': 'NGO Registration System',
  'High National Elections Commission': 'High National Elections Commission',
  'Observers Management System': "Observers' Management System",
  'Please fill in the required information': 'Please fill in the required information',
  'Customer Service': 'Customer Service',
  Back: 'Back',
  'Do you need support to complete the database': 'Do you need support to complete the database ?',
  'Have you ever cooperated with UNHCR': 'Have you ever cooperated with UNHCR ?',
  other: 'Other',
  previousElectionParticipation: 'Previous Election Participation',
  electoralCommission: 'Electoral Commission',
  issueDescriptions: 'Issue Descriptions',
  traningIssueDescriptions: 'Training Issue Descriptions',
  'Have you ever conducted programs and activities in electoral awareness and their quality?':
    'Have you ever conducted programs and activities in electoral awareness and their quality?',
  postersDistribution: 'Posters Distribution',
  advertisements: 'Advertisements',
  workshops: 'Workshops',
  otherPrograms: 'Other Programs',
  otherProgramsDescription: 'Other Programs Description',
  cooperationDescription: 'Cooperation Description',
  coalitionDescription: 'Coalition Description',
  sharedProjectsDescription: 'Shared Projects Description',
  lessonsExperience: 'Lessons Experience',
  completeProjectsDescription: 'Complete Projects Description',
  'Shared project info is required': 'Shared project info is required',
  'Have you participated in programs with other organizations that I remember?':
    "What are the organization's funding sources?",
  governmentSources: 'Governmental',
  nationalSources: 'Self',
  internationalSources: 'International',
  otherSources: 'Other',
  'What are the methods used to obtain financing?': 'What are the methods used to obtain financing?',
  commonRequest: 'Common Request',
  directOffer: 'Direct Offer',
  publicTender: 'Direct advertising from entities and companies',
  directSponsorship: 'Direct Sponsorship',
  'What are the general areas that you need support to build your capacity in?':
    'What are the general areas that you need support to build your capacity in?',
  managementCapacity: 'Management Capacity',

  networkingCapacity: 'Networking Capacity',
  computerUsageCapacity: 'Computer Usage Capacity',
  consultingCapacity: 'Consulting Capacity',
  outreachCapacity: 'Outreach Capacity',
  developmentCapacity: 'Development Capacity',
  languageLearningCapacity: 'Language Learning Capacity',
  humanDevelopmentCapacity: 'Human Development Capacity',
  strategicPlanningCapacity: 'Strategic Planning Capacity',
  mediaDocumentationCapacity: 'Media Documentation Capacity',
  internationalRelationCapacity: 'International Relation Capacity',
  financingCapacity: 'Financing Capacity',
  trainerPreparationCapacity: 'Trainer Preparation Capacity',
  leaderPreparationCapacity: 'Leader Preparation Capacity',
  monitoringManagement: 'Election monitoring',
  legalFrameworkDevelopment: 'Awareness and voter education',
  mediaManagement: 'Monitor campaign finance',
  educationSupport: 'Media monitoring',
  electoralViolencePrevention: 'Addressing electoral violence',
  monitoringCampaign: 'Hype',
  advertisingCampaign: "Women's participation",
  'Capabilities do you need to build in the electoral field?':
    'Capabilities do you need to build in the electoral field?',
  'Management dashboard': 'Management dashboard',
  'Find NGOs': 'Find NGOs',
  'Register NGO': 'Register NGO',
  Registration: 'Registration',
  'NGO List': 'NGO List',
  'Do you need support to complete the database?': 'Do you need support to complete the database?',
  'What kind of support do you need?': 'What kind of support do you need?',
  Other: 'Other',
  Submit: 'Submit',
  Search: 'Search',
  Office: 'Office',
  manager: 'Manager',
  directors: 'Director',
  'Find Registered NGO': 'Find Registered NGO',
  Reset: 'Reset',
  'Orgnization By Type': 'Orgnization By Type',
  'Full Day Report By Orgnization Type': 'Full Day Report By Orgnization Type',
  'Approved Orgnization Statistics': 'Approved Orgnization Statistics',
  Reports: 'Reports',
  'Generate Report': 'Generate Report',
  Filters: 'Filters',
  'Report - Organizations': 'Report - Organizations',
  'Report - Observer': 'Report - Observer',
  'Approved Observers': 'Approved Observers',
  'Observers By Organization': 'Observers By Organization',
  'Observers Per Office': 'Observers Per Office',
  'Observers Gender Counting': 'Observers Gender Counting',
  'Observers Detailed Data': 'Observers Detailed Data',
  'Please select the report': 'Please select the report',
  'organization types': 'organization types',
  organizations: 'organizations',
  'Report - NGO': 'Report - NGO',
  Nationalities: 'Nationalities',
  'New nationality': 'New nationality',
  'Create a new': 'Create a new',
  'What is the type of activity of the organization': 'specialized in:?',
  Youth: 'Youth',
  'The woman': 'The woman',
  'Ethnic groups': 'Ethnic groups',
  'Human rights': 'Human rights',
  'Charitable activities': 'Charitable activities',
  Elections: 'Elections',
  'Have you ever cooperated with HNEC?': 'Have you ever cooperated with HNEC?',
  'In what field did the cooperation take place?': 'In what field did the cooperation take place?',
  outreach: 'outreach',
  surveillance: 'surveillance',
  'Whether the cooperation in the field of observation? In which electoral due was held?':
    'Whether the cooperation in the field of observation? In which electoral due was held?',
  hnecCooperationConference: 'Conference',
  hnecCooperationCommision: 'Commision',
  hnecCooperationParliament: 'Parliament',
  hnecCooperationOffice: 'Office',
  cooperationOfficeID: 'Mention the electoral office in which you applied for accreditation?',
  'How was the office dealing with you in completing the procedures?':
    'How was the office dealing with you in completing the procedures?',
  Weak: 'Weak',
  Average: 'Average',
  Good: 'Good',
  'What kind of problems did you encounter in monitoring?': 'What kind of problems did you encounter in monitoring?',
  'Get training': 'Get training',
  'Complete the accreditation procedures': 'Complete the accreditation procedures',
  trainingMonitoring: 'Get training',
  accreditationMonitoring: 'Complete the accreditation procedures',
  otherMonitoring: 'Other',
  otherMonitoringDescription: 'Description',
  'Have you ever been involved in networks or spamming?': 'Have you ever been involved in networks or spamming?',
  'How was the evaluation of the experience?': 'How was the evaluation of the experience?',
  'If cooperation in the field of outreach? What activity were you collaborating on?':
    'If cooperation in the field of outreach? What activity were you collaborating on?',
  'How was the cooperation with HNEC?': 'How was the cooperation with HNEC?',
  'Do you need support to develop your cooperation with HNEC?':
    'Do you need support to develop your cooperation with HNEC?',
  'Do you need support to develop your cooperation': 'Do you need support to develop your cooperation with HNEC?',
  outreachPosterDistribution: 'Poster distribution',
  outreachAdvertisement: 'Advertisements',
  outreachWorkshop: 'Workshops',
  outreachOther: 'Other',
  'What type of support?': 'What type of support?',
  technicalSupport: 'Technical',
  logisticalSupport: 'Logistical',
  financialSupport: 'Financial',
  otherSupport: 'Other',
  "What are the organization's funding sources?": "What are the organization's funding sources?",
  'The organization has capabilities that need support in the electoral field ?':
    'The organization has capabilities that need support in the electoral field?',
  'Cooporation info is required': 'Cooporation info is required',
  'Support info is required': 'Support info is required',
  'The constituent body for drafting the constitution': 'The constituent body for drafting the constitution',
  'NGO has been registered successfully!': 'NGO has been registered successfully!',
  // ------------------------------
  'not mentioned': 'not mentioned',
  WhatsTypeOfActivity: 'What is the type of activity of the organization?',
  'If the cooperation in the field of control': 'If the cooperation in the field of monitoring? In what electoral due?',
  'NGO Update': 'NGO Update',
  'Update NGO data': 'Update NGO data',
  'Sign in to update NGO data': 'Sign in to update NGO data',
  Login: 'Login',
  ID: 'ID',
  'Access Token': 'Access Token',
  'Please update NGO data': 'Please update NGO data',
  'NGO has been updated successfully!': 'NGO has been updated successfully!',
  'ID is required': 'ID is required',
  'must be at most 13 characters': 'must be at most 13 characters',
  'Access Token is required': 'Access Token is required',
  'must be at most 16 characters': 'must be at most 16 characters',
  accessInfo: 'Please keep the ID and Access Token in a secure place to open and update your registration later',
  'training organization ?': 'training organization ?',
  formatScore:'The Result',
  totalScorePercentage: 'The Percentage',
  'NGO evaluation list': 'List of evaluation for all organizations',
};
