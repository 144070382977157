export const catalogues = {
    'Search by name': `البحث عن طريق الإسم`,

    // Genders
    Genders: `الأجناس`,
    'New gender': `جنس جديد`,
    'name is required': `مطلوب اسم`,

    // Offices
    Offices: `مكاتب`,
    'New office': `مكتب جديد`,
    'code is required': `الرمز مطلوب`,



};