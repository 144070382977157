import { menu } from './arabic/menu'
import { general } from './arabic/general'
import { catalogues } from './arabic/catalogues'
import { main } from './arabic/main'
import { ngo } from './arabic/ngo'
import { management } from './arabic/management'
import { dashboard } from './arabic/dashboard'
import {intro} from './arabic/intro'
import {observer} from './arabic/observer'
import {organization} from './arabic/organization'
import {reports} from './arabic/reports'

const ar = {
  ...menu,
  ...general,
  ...catalogues,
  ...main,
  ...ngo,
  ...management,
  ...dashboard,
  ...intro,
  ...observer,
  ...organization,
  ...reports
};

export default ar;
