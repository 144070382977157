import PropTypes from 'prop-types';
import { useAuth } from "react-oidc-context"
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const auth = useAuth();

  if (auth.isLoading) {
    return <LoadingScreen />;
  }

  if (!auth.isAuthenticated) {
    auth.signinRedirect();
  }

  return <>{children}</>;
}
