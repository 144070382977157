// @mui
import { useTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Typography, Container, Stack, Divider } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import Logo from '../../components/Logo';
import LanguagePopover from '../../components/LanguagePopover';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function MainHeader() {
  const { translate } = useLocales();

  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px 0 20px 0'
        }}
      >
        <Box>
          <Logo />
          <Typography variant="h4" noWrap ml={2} sx={{ display: 'inline-block', }} color='gray'>
            {translate('High National Elections Commission')}
          </Typography>
        </Box>

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
        </Stack>
      </Container>

      <Divider />

      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '20px 0 20px 0'
        }}
      >
        <Typography variant="h3" ml={2} sx={{ display: 'inline-block', }} color='primary.main'  >
          {translate('Observers Management System')}
        </Typography>
      </Container>
    </ThemeProvider>
  );
}
