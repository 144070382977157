export const organization = {
  'New organization type': 'New organization type',
  'New organization': 'New organization',
  'Organization Types': 'Organization Types',
  'reg. number': 'reg. number',
  director: 'director',
  address: 'address',
  type: 'type',
  'Add organization': 'Add organization',
  'phone is required': 'phone is required',
  'email is required': 'email is required',
  'address is required': 'address is required',
  'director name is required': 'director name is required',
  'type is required': 'type is required',
  'Are you sure to delete the following organization?': 'Are you sure to delete the following organization?',
  OrganizationReport1: 'Final Statistics Report',
  OrganizationReport2: 'Comprehensive Daily Report',
  OrganizationReport3: 'Statistics Report by Type',
  OrganizationReport4: "Detailed Report / Observers' Data",
  'local organization': 'local organization',
  'international organization': 'international organization',
  'training organization choices': 'training organization choices',
  'training organization Name': 'training organization Name',
  'training organization choices is required': 'training organization choices is required',
  'training organization Name is required': 'training organization Name is required',
  'Refresh evaluation': 'Refresh evaluation',
  OrganizationEvalutionReport: 'Organization Evalution Report local',
  'geographical range': 'geographical range',
  // {`${translate('Add organization2')} / ${translate('Add Media Representative')} / ${translate('Add Agent')}`}
  'Add organization2': 'Add organization',
  'Add Media Representative': 'Add Media Representative',
  'Add Agent': 'Add Agent',
  //   //  {`${translate('Edit organization')} / ${translate('Edit Media Representative')} / ${translate('Edit Agent')}`}
  'Edit organization': 'Edit organization',
  'Edit Media Representative': 'Edit Media Representative',
  'Edit Agent': 'Edit Agent',
};
