import { useState } from 'react';
// @mui
import { MenuItem, Stack, Typography, IconButton } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import { makeStyles } from "@material-ui/core/styles";
// hooks
import useLocales from '../hooks/useLocales';
// components
import MenuPopover from './MenuPopover';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export default function LanguagePopover({ color }) {
  const classes = useStyles();
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
      >
        <LanguageIcon className={classes.linkIcon} style={{ color: `${ color }` }}/>
        <Typography  ml={1} color={ color }>{currentLang.display}</Typography>
        <KeyboardArrowDown className={classes.linkIcon}  style={{ color: `${ color }` }}/>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
