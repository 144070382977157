import SvgIconStyle from '../../../components/SvgIconStyle';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// --------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

export default function getNavConfig(roles) {
  const navConfig = [];
  const currentLang = localStorage.getItem('i18nextLng');

  if (roles.includes('accreditation')) {
    const mainMeunObject = {
      subheader: 'accreditation',
      items: [
        {
          title: 'management',
          icon: ICONS.dashboard,
          children: [
            { title: 'observer', path: PATH_DASHBOARD.main.observer },
            { title: 'organization', path: PATH_DASHBOARD.main.organization },
          ],
        },
        {
          title: 'reports',
          icon: ICONS.analytics,
          children: [
            { title: 'observerMenu', path: PATH_DASHBOARD.reports.observer },
            { title: 'organizationMenu', path: PATH_DASHBOARD.reports.organization },
          ],
        },
      ],
    };
    navConfig.push(mainMeunObject);
  }

  if (roles.includes('ngo')) {
    navConfig.push({
      subheader: 'ngo',
      items: [
        {
          title: 'management',
          path: PATH_DASHBOARD.main.ngo,
          icon: ICONS.dashboard,
        },
        {
          title: 'reports',
          path: PATH_DASHBOARD.reports.ngo,
          icon: ICONS.analytics,
        },
      ],
    });
  }

  if (roles.includes('catalogues')) {
    navConfig.push({
      subheader: 'catalogues',
      items: [
        // Nationality
        {
          title: 'nationality',
          path: PATH_DASHBOARD.catalogues.nationality,
          icon: ICONS.menuItem,
        },
        // Gender
        {
          title: 'gender',
          path: PATH_DASHBOARD.catalogues.gender,
          icon: ICONS.menuItem,
        },
        // Organization Type
        {
          title: 'organization type',
          path: PATH_DASHBOARD.catalogues.organizationType,
          icon: ICONS.menuItem,
        },
        // Office
        {
          title: 'office',
          path: PATH_DASHBOARD.catalogues.office,
          icon: ICONS.menuItem,
        },
      ],
    });
  }

  if (roles.includes('admin')) {
    navConfig.push({
      subheader: 'system',
      items: [
        // User Assignement
        {
          title: 'user assignment',
          path: `${PATH_DASHBOARD.management.user_assignment}?language%3D${currentLang}`,
          icon: ICONS.user,
        },
      ],
    });
  }

  return navConfig;
}
