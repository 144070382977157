import { Outlet } from 'react-router-dom';

import { Stack } from '@mui/material';

//
import DashboardHeader from './header';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CheckLayout() {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <DashboardHeader />
      <Outlet />
    </Stack>
  );
}
