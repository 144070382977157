
// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_EXT_AUTH = 'https://idn.observers.hnec-apps.org';
// const ROOTS_EXT_AUTH = 'https://localhost:44395';

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_NGO = '/ngo';
const ROOTS_CHECK = '/check';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};

export const PATH_CHECK = {
  root: ROOTS_CHECK,
  observer: path(ROOTS_CHECK, '/observer/:id'),
};

export const PATH_NGO = {
  root: ROOTS_NGO,
  registration: path(ROOTS_NGO, '/registration'),
  success: path(ROOTS_NGO, '/success'),
  list: path(ROOTS_NGO, '/list'),
  ngoupdate: path(ROOTS_NGO, '/ngoupdate'),
  ngoupdatesuccess: path(ROOTS_NGO, '/ngoupdatesuccess'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: {
    organization: path(ROOTS_DASHBOARD, '/main/organization'),
    observer: path(ROOTS_DASHBOARD, '/main/observer'),
    ngo: path(ROOTS_DASHBOARD, '/main/ngo'),
    ngomanagement: path(ROOTS_DASHBOARD, '/main/ngomanagement'),
  },
  catalogues: {
    nationality: path(ROOTS_DASHBOARD, '/catalogues/nationality'),
    gender: path(ROOTS_DASHBOARD, '/catalogues/gender'),
    organizationType: path(ROOTS_DASHBOARD, '/catalogues/organizationType'),
    office: path(ROOTS_DASHBOARD, '/catalogues/office'),
  },
  management: {
    user_assignment: path(ROOTS_EXT_AUTH, '/user'),
  },
  reports: {
    organization: path(ROOTS_DASHBOARD, '/reports/organization'),
    observer: path(ROOTS_DASHBOARD, '/reports/observer'),
    ngo: path(ROOTS_DASHBOARD, '/reports/ngo'),
  },
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
};
