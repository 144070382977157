export const ngo = {
  'NGO Management': 'إدارة المنظمات غير الحكومية',
  'Reasone is required': 'الرجاء ذكر السبب',
  'Is the organization general or specialized, targeting a specific group or specific activity? Please mention the specialization?':
    'هل منظمتكم عامة أو خاصة، هل تستهدف فئة معينة أو أنشطة معينة؟ الرجاء ذكر الإختصاصات؟',
  'Database info is required': 'بيانات قاعدة البيانات مطلوبة',
  ageGroupSpecialization: 'الإختصاص في الفئة العمرية',
  qualitySpecialization: 'الإختصاص في الجودة',
  ethnicSpecialization: 'الإختصاص في تصنيف العرقي',
  geographicSpecialization: 'الإختصاص في المناطق',
  socialSpecialization: 'الإختصاص في الفئة الاجتماعية',
  charitySpecialization: 'الإختصاص في الاعمال الخيرية',
  humanRightSpecialization: 'الإختصاص في حقوق الانسان',
  electionSpecialization: 'الإختصاص في العمليات الانتخابية',
  otherSpecialization: 'إختصاصات أخرى',
  otherSpecializationDescription: 'وصف أخر',
  reasoneStatement: 'السبب',
  visionStatement: 'الرؤيا',
  Yes: 'نعم',
  No: 'لا',
  'Do you need support to develop your cooperation': 'هل تحتاجون الى دعم لتطوير تعاونكم مع المفوضية ؟',
  databaseObjective: 'هدف قاعدة البيانات',
  'What kind of support do you need': 'ما نوع الدعم الذي تحتاجة',
  Technical: 'تقني',
  Logistical: 'لوجستي',
  Financial: 'مالي',
  'Organization name is required': 'الرجاء إدخال إسم المنظمة',
  'Registration Number is required': 'الرجاء إدخال رقم الإشـهار   ',
  'Start Date is required': 'الرجاء إختيار تاريخ بداية النشاط',
  'Office is required': 'الرجاء إختيار المكتب',
  'Manager name is required': 'الرجاء إدخال إسم المدير',
  'Manager phone is required': 'الرجاء إدخال رقم هاتف المدير',
  'Manager email is required': 'الرجاء إدخال بريد الالكتروني مدير المنظمة',
  'Director name is required': 'الرجاء إدخال إسم رئيس المنظمة',
  'Director phone is required': 'الرجاء إدخال رقم هاتف رئيس المنظمة',
  'Email is not valid': 'البريد الإلكتروني غير صالح',
  'Staff total count is required': 'الرجاء إدخال عددالعاملين',
  'Youth staff count is required': 'الرجاء إدخال عددالعاملين الشباب',
  'Female staff count is required': 'الرجاء إدخال عددالعاملين الإناث',
  Organization: 'إسم المنظمة',
  'Start Date': 'تاريخ بداية النشاط',
  'Close Date': 'تاريخ الإغلاق',
  'Organization Name': 'إسم المنظمة',
  'Registration Number': 'رقم الإشـهار',
  managerName: 'إسم مدير المنظمة',
  managerPhone: 'هاتف مدير المنظمة',
  directorName: 'إسم رئيس المنظمة',
  directorEmail: 'البريد الإلكتروني',
  directorPhone: 'هاتف رئيس المنظمة',
  femaleStaffCount: 'عدد النساء',
  yourthStaffCount: 'عدد الشباب',
  staffCount: 'اجمالي عدد الاعضاء',
  'Geographical distribution of the organization': 'التوزيع الجغرافي للمنظمة',
  includeWest: 'غرب',
  includeEast: 'شرق',
  includeSouth: 'جنوب',
  includeCountry: 'في كامل البلاد',
  'What are the advantages of your organization compared to other civil society organizations in Libya':
    'ماهي مميزات منظمتكم مقارنتاً بباقي منظمات المجتمع المدني في ليبيا',
  officeID: 'اسم المكتب الانتخابى',
  Activities: 'النشـاط',
  'Relationship with HNEC': 'العلاقة مع المفوضية (فى مجال المراقبة)',
  'Previous Experience': 'العلاقة مع المفوضية (فى مجال التوعية)',
  Financing: 'التمويل',
  'Building Needs': 'إحتياجات المنظمة',
  Next: 'التالي',
  'Hnec Support Team': 'فريق الدعم المفوضية الوطنية العليا للانتخابات',
  'NGO Registration System': 'تسجيل منظمات المجتمع المدنى',
  'High National Elections Commission': 'المفوضية الوطنية العليا للانتخابات',
  'Observers Management System': 'منظومة اعتماد المراقبين وتسجيل منظمات المجتمع المدنى',
  'Please fill in the required information': 'الرجاء إدخال المعلومات المطلوبة',
  'Customer Service': 'خدمة العملاء',
  Back: 'السابق',
  'Do you need support to complete the database': '؟ هل تحتاج إلى دعم لإكمال قاعدة البيانات ؟',
  'Have you ever cooperated with UNHCR': 'هل سبق لك تعاون مع UNHCR ؟',
  other: 'أخرى',
  previousElectionParticipation: 'الانشطة السابقة في الانتخابات',
  electoralCommission: 'عموله انتخابيه',
  issueDescriptions: 'وصف المشكلة',
  traningIssueDescriptions: 'أوصاف مشكلة التدريب',
  'Have you ever conducted programs and activities in electoral awareness and their quality?':
    'هل سبق لك تشغيل أنشطة ومشاريع في دعم الانتخابات وجودتها ؟',
  postersDistribution: 'توزيع الملصقات',
  advertisements: 'إعلانات',
  workshops: 'ورش عمل',
  otherPrograms: 'أنشطة أخرى',
  otherProgramsDescription: 'وصف الأنشطة الأخرى',
  cooperationDescription: 'وصف التعاون',
  coalitionDescription: 'وصف التجمع',
  sharedProjectsDescription: 'وصف المشاريع المشتركة',
  lessonsExperience: 'خبرة الدروس',
  completeProjectsDescription: 'وصف المشاريع المكتملة',
  'Shared project info is required': 'الرجاء إدخال معلومات المشاريع المشتركة',
  'Have you participated in programs with other organizations that I remember?': 'ماهو مصادر تمويل المنظمة ؟',
  governmentSources: 'حكومي',
  nationalSources: 'ذاتي',
  internationalSources: 'دولي',
  otherSources: 'أخرى',
  'What are the methods used to obtain financing?': 'ما هي الأساليب المستخدمة للحصول على التمويل ؟',
  commonRequest: 'طلبات حسب الرغبة',
  directOffer: 'عرض مباشر',
  publicTender: 'الدعاية المباشرة من الجهات والشركات',
  directSponsorship: 'الترويج المباشر',
  'What are the general areas that you need support to build your capacity in?':
    'ما هي المناطق العامة التي تحتاج إلى دعم لبناء جودة المنظمة ؟',
  managementCapacity: 'جودة الإدارة',
  // translate all in arabic networkingCapacity,computerUsageCapacity,consultingCapacity,outreachCapacity,developmentCapacity,languageLearningCapacity,humanDevelopmentCapacity,strategicPlanningCapacity,mediaDocumentationCapacity,internationalRelationCapacity,financingCapacity,trainerPreparationCapacity,leaderPreparationCapacity,monitoringManagement,legalFrameworkDevelopment,mediaManagement,educationSupport,electoralViolencePrevention,monitoringCampaign,advertisingCampaign
  networkingCapacity: 'جودة الاتصال',
  computerUsageCapacity: 'جودة استخدام الحاسوب',
  consultingCapacity: 'جودة الاستشارات',
  outreachCapacity: 'جودة التوصية',
  developmentCapacity: 'جودة التنمية',
  languageLearningCapacity: 'جودة تعليم اللغة',
  humanDevelopmentCapacity: 'جودة التنمية البشرية',
  strategicPlanningCapacity: 'جودة التخطيط الإستراتيجي',
  mediaDocumentationCapacity: 'جودة التوثيق الإعلامي',
  internationalRelationCapacity: 'جودة العلاقات الدولية',
  financingCapacity: 'جودة التمويل',
  trainerPreparationCapacity: 'جودة التحضير المدرب',
  leaderPreparationCapacity: 'جودة التحضير الرئيس',
  monitoringManagement: 'مراقبة الانتخابات',
  legalFrameworkDevelopment: 'التوعية وتثقيف الناخبين',
  mediaManagement: 'مراقبة تمويل الحملات الانتخابية',
  educationSupport: 'رصد وسائل الإعلام',
  electoralViolencePrevention: 'معالجة العنف الانتخابي',
  monitoringCampaign: 'الدعاية',
  advertisingCampaign: 'مشاركة المرأة',
  'Capabilities do you need to build in the electoral field?': 'أيام الإنتخابات التي تحتاج إلى تطوير ؟',
  'Management dashboard': 'لوحة التحكم',
  'Find NGOs': 'البحث عن منظمات',
  'Register NGO': 'تسجيل منظمة',
  Registration: 'تسجيل',
  'NGO List': 'قائمة المنظمات',
  registrationNumber: 'رقم الإشـهار',
  RegistrationNumberFile: 'ورقة صورة الإشهار',
  organizationFeatures: 'مميزات المنظمة',
  'Do you need support to complete the database?': 'هل تحتاج إلى دعم لإكمال قاعدة البيانات ؟',
  'What kind of support do you need?': 'ما هو الدعم الذي تحتاجه ؟',
  Other: 'الأخرى',
  Submit: 'إرسال',
  Search: 'بحث',
  Office: 'المكتب الانتخابي',
  manager: ' مدير المنظمة',
  directors: 'رئيس المنظمة',
  'Find Registered NGO': 'ابحث عن المنظمات المسجلة',
  Reset: 'إعادة تعيين',
  'Orgnization By Type': 'المنظمات حسب النوع',
  'Full Day Report By Orgnization Type': 'تقرير يوم كامل حسب نوع المنظمة',
  'Approved Orgnization Statistics': 'احصائيات المنظامات المعتمدة',
  Reports: 'التقارير',
  'Generate Report': 'انشاء التقرير',
  Filters: 'التصفية حسب',
  'Report - Organizations': 'تقرير - المنظمات',
  'Report - Observer': 'تقرير - المراقبين',
  'Approved Observers': 'المراقبين المعتمدين',
  'Observers By Organization': 'المراقبين حسب المنظمات',
  'Observers Per Office': 'المراقبين لكل مكتب',
  'Observers Gender Counting': 'تعداد المراقبون بين الجنسين',
  'Observers Detailed Data': 'بيانات المراقبين المفصلة',
  'Please select the report': 'الرجاء اختيار التقرير',
  'organization types': 'انواع المنظمات',
  organizations: 'المنظمات',
  'Report - NGO': 'تقرير - المظمات',
  Nationalities: 'الجـنسيات',
  'New nationality': 'جنسيات جديدة',
  'Create a new': 'انشاء جديد',
  'What is the type of activity of the organization': 'هل تختص ب: ؟',
  Youth: 'الشباب',
  'The woman': 'المرأة',
  'Ethnic groups': 'الفئات العرقية',
  'Human rights': 'حقوق الانسان',
  'Charitable activities': 'الأنشطة الخيرية',
  Elections: 'الانتخابات',
  'Have you ever cooperated with HNEC ?': 'هل سبق لك التعاون مع المفوضية؟',
  'In what field did the cooperation take place?': 'إذا كانت الإجابة نعم في اي مجال تم التعاون؟',
  outreach: 'التوعية',
  surveillance: 'المراقبة',
  'Have you ever cooperated with HNEC?': 'هل سبق لك التعاون مع المفوضية؟',
  'Whether the cooperation in the field of observation? In which electoral due was held?':
    'إذا كان التعاون في مجال المراقبة؟ في أي إستحقاق إنتخابي تم؟',
  hnecCooperationConference: 'المؤتمر الوطنى العام',
  hnecCooperationCommision: 'الهيئة التاسيسية لصياغة الدستور',
  hnecCooperationParliament: 'الانتخابات البرلمانية',
  hnecCooperationOffice: 'المكاتب',
  cooperationOfficeID: 'ادكر المكتب الانتخابى الدى تقدمت بطلب الاعتماد فيه ؟',
  'How was the office dealing with you in completing the procedures?': 'كيف كان تعامل المكتب معكم في اتمام الاجراءات؟',
  Weak: 'ضعيف',
  Average: 'متوسط',
  Good: 'جيد',
  'What kind of problems did you encounter in monitoring?': 'ما نوع المشاكل التي واجهتكم في المراقبة؟',
  'Get training': 'الحصول على التدريب',
  'Complete the accreditation procedures': 'إتمام إجراءات الإعتماد',
  trainingMonitoring: 'الحصول على التدريب',
  accreditationMonitoring: 'إتمام إجراءات الإعتماد',
  otherMonitoring: 'أخرى',
  otherMonitoringDescription: 'وصف المراقبة',
  'Have you ever been involved in networks or spamming?': 'هل سبق لكم الدخول في شبكات أو ائتلاف ؟',
  'How was the evaluation of the experience?': 'إذا كانت الإجابة نعم كيف كان تقييم التجربة؟',
  'If cooperation in the field of outreach? What activity were you collaborating on?':
    'إذا كان التعاون في مجال التوعية؟ ما هو النشاط الذي تم التعاون فيه؟',
  'How was the cooperation with HNEC?': 'كيف كان التعاون مع المفوضية؟',
  'Do you need support to develop your cooperation with HNEC?': 'هل تحتاجون إلى دعم لتطوير تعاونكم مع المفوضية؟',
  outreachPosterDistribution: 'توزيع الملصقات',
  outreachAdvertisement: 'دعايات',
  outreachWorkshop: 'ورش عمل',
  outreachOther: 'اخرى',
  'What type of support?': 'إذا كانت الإجابة نعم ما نوع الدعم؟',
  technicalSupport: 'فني',
  logisticalSupport: 'لوجستي',
  financialSupport: 'مالي',
  otherSupport: 'اخري',
  "What are the organization's funding sources?": 'ماهي مصادر تمويل المنظمة ؟',
  'The organization has capabilities that need support in the electoral field ?':
    'للمنظمة قدرات تحتاج الي دعم في المجال الانتخابي حددها ؟',
  'Cooporation info is required': 'مطلوب معلومات التعاون',
  'Support info is required': 'معلومات الدعم مطلوبة',
  'The constituent body for drafting the constitution': 'الهيئة التاسيسية لصياغة الدستور',
  'NGO has been registered successfully!': 'تم تسجيلكم بنجاح',
  // ---------------------
  'not mentioned': 'لم يذكر',
  WhatsTypeOfActivity: 'ما نوع نشـاط المنظمة ؟',
  'If the cooperation in the field of control': 'اذا كان التعاون في مجال المراقبة ؟ في اي استحقاق انتخابي ؟',
  'NGO Update': 'تحديث بيانات منظمات المجتمع المدنى',
  'Update NGO data': 'تحديث بيانات المنظمة',
  'Sign in to update NGO data': 'تسجيل الدخول لتحديث بيانات المنظمة',
  Login: 'تسجيل الدخول',
  ID: 'رقم التسجيل',
  'Access Token': 'رمز الدخول',
  'Please update NGO data': 'يرجى تحديث بيانات المنظمة',
  'NGO has been updated successfully!': 'تم تحديث بيانات المنظمة بنجاح',
  'ID is required': 'مطلوب رقم التسجيل',
  'must be at most 13 characters': 'يجب أن يكون 13 حرفا على الأكثر',
  'Access Token is required': 'مطلوب رمز الدخول',
  'must be at most 16 characters': 'يجب أن يكون 16 حرفا على الأكثر',
  accessInfo: 'يرجى الاحتفاظ برقم التسجيل ورمز الدخول في مكان آمن لفتح وتحديث تسجيلكم في وقت لاحق',
  'training organization ?' : 'منظمة التدريب ؟',
  formatScore:'النتيجة',
  totalScorePercentage: 'النسبة المئوية',
  'NGO evaluation list' : 'قائمة تقييم جميع المنظمات',
};
