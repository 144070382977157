export const observer = {
  'code is required': 'الرجاء إدخال رقم الاشهار ',
  'name is required': 'الرجاء إدخال الاسـم',
  'personal ID is required': 'الرجاء إدخال الرقم الوطني',
  'organization is required': 'التنظيم مطلوب',
  'Are you sure to delete the following observer?': 'هل أنت متأكد من حذف المراقب التالي؟',
  Observers: 'المراقبين',
  status: 'الحالة',
  'Is Director?': 'هل هو/هي المندوب؟',
  'Add observer': 'أضف مراقب',
  Observercode: 'رقم البطاقة',
  ObserverReport1: 'تقرير أعداد المعتمدين / حسب التصنيف',
  ObserverReport2: 'تقرير تفصيلي / بيانات أفراد',
  ObserverReport3: 'تقرير تفصيلي / بيانات جهات',
  ObserverReport4: 'تقرير تفصيلي للمعتمدين في كل مكتب',
  ObserverReport5: 'تقارير معتمدين حسب المكتب',
  ObserverReport6: 'تقرير أعداد المعتمدين حسب المكتب',
  IsHasTrainingCertificate: 'هل تحصل على شهادة تدريبية ؟',
  'passport is required': 'الرجاء ادخال رقم الجواز',
  passport: 'رقم الجواز',
  'personal ID or passport is required': 'الرجاء ادخال الرقم الوطني او رقم الجواز',
  'office is required': 'الرجاء ادخال المكتب',
  'gender is required': 'الرجاء ادخال النوع',
  'Add Observer': 'أضف مراقب',
  'Add Media Representative': 'أضف ممثل إعلامي',
  'Add Agent': 'أضف وكيل',
  //   {`${translate('Edit')} ${translate('Edit Observer')} / ${translate('Edit Media Representative')} / ${translate('Edit Agent')}`}
  'Edit Observer': 'تعديل المراقب',
  'Edit Media Representative': 'تعديل الممثل الإعلامي',
  'Edit Agent': 'تعديل الوكيل',
  "Add": "أضف",
  "Edit": "تعديل",

};
