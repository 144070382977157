export const general = {
  general: `عام`,
  user: `المستخدم`,
  name: `اسم`,
  code: `رقم الاشهار`,
  none: `لا أحد`,
  translation: `ترجمة`,
  list: `قائمة`,
  edit: `تعديل`,
  create: `إضافة`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  details: `تفاصيل`,
  description: `وصف`,
  all: `الكل`,
  All: `الكل`,
  'New observer': `إضافة مراقب جديد`,
  'personal ID': `الرقم الوطني`,
  genderName: `الجنس`,
  phone: `رقم الهاتف`,
  email: `البريد الالكتروني`,
  NGOs: 'منظمات المجتمع المدني',

  // Buttons
  Delete: `حذف`,
  Edit: `تعديل`,
  Translate: `ترجمة`,
  Badge: `بطاقة`,
  Approve: `موافقة`,
  Assign: `تعيين`,
  Details: 'تفاصيل',

  // Actions
  Add: `إضافة`,
  Update: `تحديث`,
  Cancel: `إلغاء`,

  // Notifications
  'Are you sure to delete the following item': `هل أنت متأكد من حذف العنصر التالي`,
  'Update the following item': `قم بتحديث العنصر التالي`,
  'Translate the following item': `ترجم العنصر التالي`,
  'The item has been added successfully!': `تمت إضافة العنصر بنجاح`,
  'The item has not been added!': `لم يتم إضافة العنصر`,
  'The item has been translated successfully!': `تمت ترجمة العنصر بنجاح`,
  'The item has not been translated!': `لم تتم ترجمة العنصر`,
  'The item has been updated successfully!': `تم تحديث العنصر بنجاح`,
  'The item has not been updated!': `لم يتم تحديث العنصر`,
  'The item has been deleted successfully!': `تم حذف العنصر بنجاح`,
  'The item has not been deleted!': `لم يتم حذف العنصر`,
  'The record has been approved successfully!': `تمت الموافقة على السجل بنجاح`,
  'The record has not been approved!': `لم تتم الموافقة عليه سجل`,
  'Assign to the following user': `تعيين للمستخدم التالي`,
  'Are you sure to approve the following': `هل أنت متأكد من الموافقة على ما يلي`,
  'Type to search...': `اكتب للبحث`,
  'Upload Image': `تحميل الصور`,
  'Search by name': 'البحث بالاسم',
  'reg. number is required': 'الرجاء إدخال رقم الاشهار',
  'name is required': 'الرجاء إدخال الإسم',
  'organization Type is required': 'الرجاء إختيار تصنيف المنظمة',
};
