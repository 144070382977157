import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {};

const slice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    resetUserProfile: () => initialState,

    setUserProfile(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetUserProfile, setUserProfile } = slice.actions;