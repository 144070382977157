import { m } from 'framer-motion';
import { Outlet } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  },
}));

// ----------------------------------------------------------------------

export default function MainLayout() {
  return (
    <RootStyle>
        <MainHeader />
        <Outlet />
    </RootStyle>
  );
}
