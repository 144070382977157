import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {};

const slice = createSlice({
  name: 'localtable',
  initialState,
  reducers: {
    resetLocalTableParams: () => initialState,

    setLocalTableParams(state, action) {
      Object.assign(state, action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetLocalTableParams, setLocalTableParams } = slice.actions;