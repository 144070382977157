export const menu = {
  management: `management`,
  gender: `gender`,
  catalogues: `catalogues`,
  nationality: `nationality`,
  'organization type': `organization type`,
  office: `office`,
  observer: 'observer',
  observerMenu: 'Detailed Statistics',
  organization: 'organization',
  organizationMenu: 'Final statistics',
  ngo: 'ngo',
  'ngo management': 'ngo management',
  reports: 'reports',
  'user assignment': 'user assignment',
  accreditation: 'accreditation',
  system: 'system',
};
