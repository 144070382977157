export const dashboard = {
    Agent:'Agent',
    'Local Observer': 'Local Observer',
    'Local Media': 'Local Media',
    'International Observer': 'International Observer',
    'International Media': 'International Media',
    Guest:'Guest',
    Candidate:'Candidate',
    'Local Organization':'Local Organization',
    'Local Media Authority':'Local Media Authority',
    'International Organization':'International Organization',
    'International Media Authority':'International Media Authority',
    'Diplomatic Mission':'Diplomatic Mission',
    'Total':'Total',
    'Registered NGO':'Registered NGO',
    'Non Approved NGO':'Non Approved NGO'
};