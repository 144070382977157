import PropTypes from 'prop-types';
// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// config
import { oidcConfig } from '../config'

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  user: PropTypes.object,
};


export default function MyAvatar({ user, ...other }) {

  return (
    <Avatar
      src={user.photoURL && `${oidcConfig.authority}/avatars/${user.photoURL}`}
      alt={user.username}
      color={user.photoURL ? 'default' : createAvatar(user.username).color}
      {...other}
    >
      {createAvatar(user?.username).name}
    </Avatar>
  );
}
