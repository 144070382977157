import { useState } from 'react';
import { useAuth } from "react-oidc-context"

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Typography, MenuItem } from '@mui/material';

// redux
import { useSelector } from '../redux/store';

// components
import MyAvatar from './MyAvatar';
import MenuPopover from './MenuPopover';
import { IconButtonAnimate } from './animate';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function AccountPopover() {

  const userProfile = useSelector((state) => state.userProfile);
  const auth = useAuth();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar user={userProfile}/>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userProfile.username}
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {userProfile.firstname} {userProfile.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userProfile.email}
          </Typography>
        </Box>

        <MenuItem onClick={auth.signoutRedirect} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
