export const menu = {
  Dashboard: `لوحة القيادة`,
  dashboard: `لوحة القيادة`,
  main: `رئيسي`,
  Main: `رئيسي`,
  management: `برنامج`,
  gender: `النوع`,
  catalogues: `كتالوجات`,
  Catalogues: `كتالوجات`,
  nationality: `جنسية`,
  'organization type': `تصنيف المنظمة`,
  office: `مكتب`,
  observer: 'مراقبين',
  observerMenu: 'الاحصائيات التفصيلية',
  organization: 'منظمات',
  organizationMenu: 'الإحصائيات النهائية',
  ngo: 'تسجيل منظمات المجتمع المدني',
  'ngo management': 'إدارة المنظمات غير الحكومية',
  reports: 'تقارير',
  'user assignment': 'مستخدمين',
  accreditation: 'اعتماد المراقبين',
  system: 'النظام',
};
