// @mui
import { enUS, arSD } from '@mui/material/locale';

// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
export const BASE_API_URL = `https://test-api.observers.hnec-apps.org/api/`;

// export const BASE_API_URL = `https://localhost:44390/api/`;

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// Local
// export const oidcConfig = {
//   authority: "https://localhost:44395",
//   client_id: "localhost",
//   redirect_uri: "https://localhost:4200/dashboard/app",
//   post_logout_redirect_uri: "https://localhost:4200",
//   scope: 'openid profile roles resourceServerScope',
// };

// Staging
export const oidcConfig = {
  authority: 'https://test-idn.observers.hnec-apps.org',
  client_id: 'test-observers-hnec-apps-org',
  redirect_uri: 'https://test-observers.hnec-apps.org/dashboard/app',
  post_logout_redirect_uri: 'https://test-observers.hnec-apps.org',
  scope: 'openid profile roles resourceServerScope',
};

// Remote
// export const oidcConfig = {
//   authority: 'https://idn.observers.hnec-apps.org',
//   client_id: 'observers-hnec-ly',
//   redirect_uri: 'https://observers.hnec.ly/dashboard/app',
//   post_logout_redirect_uri: 'https://observers.hnec.ly',
//   scope: 'openid profile roles resourceServerScope',
// };

// Remote
// export const oidcConfig = {
// authority: 'https://idn.observers.hnec-apps.org',
// client_id: 'observers-hnec-apps-org',
// redirect_uri: 'https://observers.hnec-apps.org/dashboard/app',
// post_logout_redirect_uri: 'https://observers.hnec-apps.org',
// scope: 'openid profile roles resourceServerScope',
// };

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
export const allLangs = [
  {
    label: 'English',
    value: 'en',
    display: 'En',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'عربي',
    value: 'ar',
    display: 'Ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },
];

export const defaultLang = allLangs[1]; // Arabic
