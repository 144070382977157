export const organization = {
  'New organization type': 'نوع منظمة جديد',
  'New organization': 'منظمة جديدة',
  'Organization Types': 'أنواع المنظمات',
  'reg. number': 'رقم الاشهار',
  director: 'إسم المدير',
  address: 'العنوان',
  type: 'تصنيف',
  'Add organization': 'أضف منظمة',
  'phone is required': 'الهاتف مطلوب',
  'email is required': 'البريد الالكتروني مطلوب',
  'address is required': 'العنوان مطلوب',
  'director name is required': 'مطلوب اسم المخرج',
  'type is required': 'النوع مطلوب',
  'Are you sure to delete the following organization?': 'هل أنت متأكد من حذف المنظمة التالية؟',
  OrganizationReport1: 'الاحصائيات النهائية',
  OrganizationReport2: 'تقرير يومي شامل',
  OrganizationReport3: 'تقرير احصائيات حسب النوع',
  OrganizationReport4: 'بیانات تفصیلیة / المراقبین',
  'international organization': 'منظمة دولية',
  'local organization': 'منظمة محلية',
  'training organization choices': 'خيارات الجهة التدريبية',
  'training organization Name': 'اسم الجهة التدريبية',
  'training organization choices is required': 'خيارات الجهة التدريبية مطلوبة',
  'training organization Name is required': 'اسم الجهة التدريبية مطلوب',
  'Refresh evaluation': 'تحديث التقييم',
  OrganizationEvalutionReport: 'تقرير تقييم المنظمة المحلية',
  'geographical range': 'المدى الجغرافي',
  'Add organization2': 'أضف منظمة',
  'Add Media Representative': 'أضف ممثل إعلامي',
  'Add Agent': 'أضف وكيل',
  //  {`${translate('Edit organization')} / ${translate('Edit Media Representative')} / ${translate('Edit Agent')}`}
  'Edit organization': 'تعديل المنظمة',
  'Edit Media Representative': 'تعديل الممثل الإعلامي',
  'Edit Agent': 'تعديل الوكيل',
};
