export const observer = {
  'code is required': 'code is required',
  'name is required': 'name is required',
  'personal ID is required': 'National ID is required',
  'organization is required': 'organization is required',
  'Are you sure to delete the following observer?': 'Are you sure to delete the following observer?',
  Observers: 'Observers',
  status: 'status',
  'Is Director?': 'Is Director?',
  'Add observer': 'Add observer',
  Observercode: 'Card number',
  ObserverReport1: 'Report On Number Of Accredited / By Type',
  ObserverReport2: 'Detailed Report / Individual Data',
  ObserverReport3: 'Detailed Report / Entity Data',
  ObserverReport4: 'Detailed  Report Of Accredited In Each Office ',
  ObserverReport5: 'Accredited Reports by Office',
  ObserverReport6: 'Report On Number Of Accredited / By Office',
  IsHasTrainingCertificate: 'Do you have a training certificate ?',
  'passport is required': 'passport is required',
  passport: 'Passport No',
  'personal ID or passport is required': 'National ID or Passport No is required',
  'office is required': 'office is required',
  'gender is required': 'gender is required',
  //  // {`${translate('Add Observer')} / ${translate('Add Media Representative')} / ${translate('Add Agent')}`}
  'Add Observer': 'Add Observer',
  'Add Media Representative': 'Add Media Representative',
  'Add Agent': 'Add Agent',
  //   {`${translate('Edit')} ${translate('Edit Observer')} / ${translate('Edit Media Representative')} / ${translate('Edit Agent')}`}
  'Edit Observer': 'Edit Observer',
  'Edit Media Representative': 'Edit Media Representative',
  'Edit Agent': 'Edit Agent',
  'Add': 'Add',
  'Edit': 'Edit',



};
