import PropTypes from 'prop-types';
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';

// auth
import { useAuth } from 'react-oidc-context';

// axios
import axios from 'axios';

// redux
import { useDispatch } from '../redux/store';

// redux slices
import { resetUserProfile, setUserProfile } from '../redux/slices/userProfile';

// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import NGORegistrationLayout from '../layouts/ngo-registration';
import NGOUpdateLayout from '../layouts/ngo-update';
import NGOListLayout from '../layouts/ngo-list';
import CheckLayout from '../layouts/check';
// guards
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN, oidcConfig } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const RequireAuth = ({ allowedRoles }) => {
  const auth = useAuth();
  const location = useLocation();
  let roles = auth?.user?.profile.role;
  if (typeof roles === 'string') {
    roles = [roles];
  }

  return (
    // eslint-disable-next-line no-nested-ternary
    roles && roles.find((role) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : (
      <Navigate to="/403" state={{ from: location }} replace />
    )
    // : auth?.user
    //   ? <Navigate to="/Unauthorized" state={{ from: location }} replace />
    //   : <Navigate to="/Login" state={{ from: location }} replace />
  );
};

RequireAuth.propTypes = {
  allowedRoles: PropTypes.array,
};

export default function Router() {
  const dispatch = useDispatch();
  const auth = useAuth();
  useEffect(() => {
    const token = auth.user?.access_token;
    if (token) {
      axios
        .get(`${oidcConfig.authority}/connect/userinfo`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          dispatch(resetUserProfile());
          dispatch(setUserProfile(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [auth.user?.access_token, dispatch]);

  return useRoutes([
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'catalogues',
          element: <RequireAuth allowedRoles={['catalogues']} />,
          children: [
            { path: 'nationality', element: <Nationality /> },
            { path: 'gender', element: <Gender /> },
            { path: 'organizationType', element: <OrganizationType /> },
            { path: 'office', element: <Office /> },
          ],
        },
        {
          path: 'main',
          children: [
            {
              element: <RequireAuth allowedRoles={['accreditation']} />,
              children: [{ path: 'organization', element: <Organization /> }],
            },
            {
              element: <RequireAuth allowedRoles={['accreditation']} />,
              children: [{ path: 'observer', element: <Observer /> }],
            },
            {
              element: <RequireAuth allowedRoles={['ngo']} />,
              children: [{ path: 'ngo', element: <Ngo /> }],
            },
            {
              element: <RequireAuth allowedRoles={['ngo']} />,
              children: [{ path: 'ngomanagement', element: <NgoManagement /> }],
            },
          ],
        },
        {
          path: 'reports',
          element: <RequireAuth allowedRoles={['reports']} />,
          children: [
            { path: 'organization', element: <OrganizationReport /> },
            { path: 'observer', element: <ObserverReport /> },
            { path: 'ngo', element: <NgoReport /> },
          ],
        },
      ],
    },

    // NGO Registration Routes
    {
      path: 'ngo',
      element: <NGORegistrationLayout />,
      children: [
        { path: 'registration', element: <Registration /> },
        { path: 'success', element: <Success /> },
      ],
    },

    // NGO Update Routes
    {
      path: 'ngo',
      element: <NGOUpdateLayout />,
      children: [
        { path: 'ngoupdate', element: <NGOUpdate /> },
        { path: 'ngoupdatesuccess', element: <NGOUpdateSuccess /> },
      ],
    },

    // NGO List Routes
    {
      path: 'ngo',
      element: <NGOListLayout />,
      children: [{ path: 'list', element: <List /> }],
    },

    // Check Routes
    {
      path: 'check',
      element: <CheckLayout />,
      children: [{ path: 'observer/:id', element: <Check /> }],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: '/',
      element: <MainLayout />,
      children: [{ element: <HomePage />, index: true }],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// MAIN
const Organization = Loadable(lazy(() => import('../pages/dashboard/main/organization/index')));
const Observer = Loadable(lazy(() => import('../pages/dashboard/main/observer/index')));
const Ngo = Loadable(lazy(() => import('../pages/dashboard/main/ngo/index')));
const NgoManagement = Loadable(lazy(() => import('../pages/dashboard/main/ngo-management/index')));

// CATALOGUES
const Nationality = Loadable(lazy(() => import('../pages/dashboard/catalogues/Nationality')));
const Gender = Loadable(lazy(() => import('../pages/dashboard/catalogues/Gender')));
const OrganizationType = Loadable(lazy(() => import('../pages/dashboard/catalogues/OrganizationType')));
const Office = Loadable(lazy(() => import('../pages/dashboard/catalogues/Office')));

// REPORTS
const OrganizationReport = Loadable(lazy(() => import('../pages/dashboard/reports/Organization')));
const ObserverReport = Loadable(lazy(() => import('../pages/dashboard/reports/Observer')));
const NgoReport = Loadable(lazy(() => import('../pages/dashboard/reports/Ngo')));

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// NGO Registration
const Registration = Loadable(lazy(() => import('../pages/ngo-registration')));
const Success = Loadable(lazy(() => import('../pages/ngo-registration/Success')));

// NGO Update
const NGOUpdate = Loadable(lazy(() => import('../pages/ngo-update')));
const NGOUpdateSuccess = Loadable(lazy(() => import('../pages/ngo-update/Success')));

// NGO List
const List = Loadable(lazy(() => import('../pages/ngo-list')));

// Check
const Check = Loadable(lazy(() => import('../pages/check-observer')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
